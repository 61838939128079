import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Landing from "./pages/landing/Landing";
import Video from "./pages/landing/Video";
import Tutorial from "./pages/tutorial/Tutorial";
import LocationOne from "./pages/locations/LocationOne";
import LocationTwo from "./pages/locations/LocationTwo";
import LocationThree from "./pages/locations/LocationThree";
import NotFound from "./pages/general/NotFound";

function App() {
  let location = useLocation();
  return (
    <div className="App">
      <TransitionGroup>
        {/*
            This is no different than other usage of
            <CSSTransition>, just make sure to pass
            `location` to `Switch` so it can match
            the old location as it animates out.
          */}
        <CSSTransition key={location.pathname} classNames="fade" timeout={1000}>
          <Routes>
            <Route path="/" element={<Landing />}></Route>
            <Route path="/video" element={<Video />}></Route>
            <Route path="/tutorial" element={<Tutorial />}></Route>
            <Route path="/public-entrance" element={<LocationOne />}></Route>
            <Route path="/copper-gate" element={<LocationTwo />}></Route>
            <Route path="/chamber" element={<LocationThree />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default App;
