import React from "react";
import Button from "@mui/material/Button";
import slEmblem from "../../images/emblem.png";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();

  const navigateToVideo = () => navigate("/video");
  const navigateToTutorial = () => navigate("/tutorial");

  return (
    <div className="video-page-container">
      <div className="video-bg">
        <ReactPlayer 
          url="https://www.youtube.com/watch?v=Swc-7lwdYTA"
          width="100vw"
          height="100vh"
          loop="true"
          playing="true" 
          muted="true"
          controls="false"
        />
        <div className="landing-container">
          <div className="center-container">
            <div className="landing-center-container">
              <div className="flex-column-container">
                <img className="sl-emblem" src={slEmblem} alt="" />
                <span className="landing-heading">VR TOUR OF</span>
                <span className="landing-heading">PARLIAMENT OF SRI LANKA</span>
              </div>
              <div className="flex-column-container language-div">
                <span className="language-select-text">
                  SELECT LANGUAGE TO START TOUR
                </span>
                <Button
                  className="language-btn"
                  variant="contained"
                  onClick={navigateToTutorial}
                >
                  English
                </Button>
                <Button
                  className="language-btn"
                  variant="contained"
                  onClick={navigateToTutorial}
                >
                  සිංහල
                </Button>
                <Button
                  className="language-btn"
                  variant="contained"
                  onClick={navigateToTutorial}
                >
                  தமிழ்
                </Button>
              </div>
            </div>
            <span className="background-video-link" onClick={navigateToVideo}>
              WATCH BACKGROUND VIDEO
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
