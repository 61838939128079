import React, { useState, useEffect } from "react";
import { Pannellum } from "pannellum-react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuIcon from "@mui/icons-material/Menu";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Fullscreen from "fullscreen-react";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import projectorIcon from "../../images/projector-icon.png";
import locationDialogPublic from "../../images/locations/public_1.jpg";
import locationDialogCopperGate from "../../images/locations/copper_gate_1.jpg";
import locationDialogChamber from "../../images/locations/chamber_1.jpg";
import locationOne from "../../images/locations/chamber_1.jpg";
import locationTwo from "../../images/locations/chamber_2.jpg";
import locationThree from "../../images/locations/chamber_3.jpg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LocationThree = () => {
  const [open, setOpen] = React.useState(false);
  const [imageSrc, setImageSrc] = React.useState("");
  const [imageIndex, setImageIndex] = React.useState(1);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setImageSrc(locationOne);
    setImageIndex(1);
  }, []);

  const changeImage = (id) => {
    switch (id) {
      case 1:
        setImageSrc(locationOne);
        setImageIndex(1);
        break;
      case 2:
        setImageSrc(locationTwo);
        setImageIndex(2);
        break;
      case 3:
        setImageSrc(locationThree);
        setImageIndex(3);
        break;
      default:
        break;
    }
  };

  const goPrevious = () => {
    switch (imageIndex) {
      case 1:
        navigate("/copper-gate");
        break;
      case 2:
        setImageSrc(locationOne);
        setImageIndex(1);
        break;
      case 3:
        setImageSrc(locationTwo);
        setImageIndex(2);
        break;
      default:
        break;
    }
  };

  const goNext = () => {
    switch (imageIndex) {
      case 1:
        setImageSrc(locationTwo);
        setImageIndex(2);
        break;
      case 2:
        setImageSrc(locationThree);
        setImageIndex(3);
        break;
      case 3:
        break;
      default:
        break;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goHome = () => {
    setAnchorEl(null);
    navigate("/");
  };

  const [isEnter, setIsEnter] = useState(false);

  const navigatePublicEntrance = () => {
    navigate("/public-entrance");
  };

  const navigateCopperGate = () => {
    navigate("/copper-gate");
  };

  const navigateChamber = () => {
    navigate("/chamber");
  };

  const [isDrag, setIsDrag] = React.useState(false);

  const onDrag = () => {
    setIsDrag(true);
  };

  const onDragLeave = () => {
    setIsDrag(false);
  };

  return (
    <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
      <div className="page-container">
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          className="locations-dialog"
          fullScreen
        >
          <IconButton className="close-icon" onClick={handleClose}>
            <CancelIcon />
          </IconButton>
          <DialogContent>
            <div className="dialog-header-row">
              <span className="dialog-header">All Locations</span>
            </div>
            <div className="location-grid">
              <div className="location-card" onClick={navigatePublicEntrance}>
                <div className="location-details">
                  <span className="location-detail-name">Public Entrance</span>
                  <CheckCircleIcon className="location-tick" />
                </div>
                <img
                  className="location-dialog-img"
                  src={locationDialogPublic}
                  alt=""
                />
              </div>
              <div className="location-card" onClick={navigateCopperGate}>
                <div className="location-details">
                  <span className="location-detail-name">Copper Gate</span>
                  <CheckCircleIcon className="location-tick" />
                </div>
                <img
                  className="location-dialog-img"
                  src={locationDialogCopperGate}
                  alt=""
                />
              </div>
              <div className="location-card" onClick={navigateChamber}>
                <div className="location-details">
                  <span className="location-detail-name">Chamber</span>
                  <CheckCircleIcon className="location-tick" />
                </div>
                <img
                  className="location-dialog-img"
                  src={locationDialogChamber}
                  alt=""
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <IconButton className="mob-menu-btn" onClick={handleMenuOpen}>
          <MenuIcon />
        </IconButton>
        <Menu
          className="menu-container"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={goHome}>
            <div className="home-icon"></div>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <img className="footer-icon" src={projectorIcon} alt="" />
          </MenuItem>
        </Menu>

        <Pannellum
          width="100%"
          height="100vh"
          image={imageSrc}
          pitch={-5}
          yaw={360}
          hfov={100}
          autoLoad
          showZoomCtrl={false}
          showFullscreenCtrl={false}
          onMousedown={(evt) => {
            onDrag();
          }}
          onMouseup={(evt) => {
            onDragLeave();
          }}
          onTouchstart={(evt) => {
            onDrag();
          }}
          onTouchend={(evt) => {
            onDragLeave();
          }}
        ></Pannellum>
        <div
          className={[
            "footer-container",
            isDrag ? "footer-fade-out" : "footer-fade-in",
          ].join(" ")}
        >
          <Button
            onClick={handleClickOpen}
            className="locations-btn"
            variant="outlined"
            startIcon={<MenuIcon />}
          >
            All Locations
          </Button>
          <div className="stepper-container">
            <IconButton className="prev-next-icon" onClick={() => goPrevious()}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <div className="stepper-nav-container">
              <div className="stepper-locations">
                <span
                  className="stepper-location-name"
                  onClick={navigateCopperGate}
                >
                  Copper Gate
                </span>
                <span className="stepper-location-name-active mlm-4">
                  Chamber
                </span>
                <span className="stepper-location-name">Location</span>
              </div>
              <div className="stepper-navigations">
                <span
                  className="stepper-navigations-name"
                  onClick={() => goPrevious()}
                >
                  Previous
                </span>
                <span className="stepper-line-left"></span>
                <div className="stepper-circle-container">
                  <section
                    className={["mr-10", imageIndex === 1 ? "pulse" : ""].join(
                      " "
                    )}
                    onClick={() => changeImage(1)}
                  >
                    <div
                      className={[
                        "stepper-circle-multi",
                        imageIndex === 1 ? "active-stepper" : "",
                        imageIndex === 1 ? "fade-in-fwd" : "",
                      ].join(" ")}
                    >
                      <div className="stepper-circle-multi-inner"></div>
                    </div>
                  </section>

                  <section
                    className={["mr-10", imageIndex === 2 ? "pulse" : ""].join(
                      " "
                    )}
                    onClick={() => changeImage(2)}
                  >
                    <div
                      className={[
                        "stepper-circle-multi",
                        imageIndex === 2 ? "active-stepper" : "",
                        imageIndex === 2 ? "fade-in-fwd" : "",
                      ].join(" ")}
                    >
                      <div className="stepper-circle-multi-inner"></div>
                    </div>
                  </section>

                  <section
                    className={["", imageIndex === 3 ? "pulse" : ""].join(" ")}
                    onClick={() => changeImage(3)}
                  >
                    <div
                      className={[
                        "stepper-circle-multi",
                        imageIndex === 3 ? "active-stepper" : "",
                        imageIndex === 3 ? "fade-in-fwd" : "",
                      ].join(" ")}
                    >
                      <div className="stepper-circle-multi-inner"></div>
                    </div>
                  </section>
                </div>
                <span className="stepper-line-right"></span>
                <span
                  className="stepper-navigations-name"
                  onClick={() => goNext()}
                >
                  Next
                </span>
              </div>
            </div>
            <IconButton className="prev-next-icon" onClick={() => goNext()}>
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
          <div className="footer-icon-container">
            <div className="home-icon" onClick={goHome}></div>
            <img className="footer-icon" src={projectorIcon} alt="" />
            <div
              className="full-screen-icon"
              onClick={() => {
                setIsEnter(!isEnter);
              }}
            ></div>
          </div>
        </div>
        <div
          className={[
            "footer-container-mob",
            isDrag ? "footer-fade-out" : "footer-fade-in",
          ].join(" ")}
        >
          <IconButton className="prev-next-icon" onClick={() => goPrevious()}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <div className="flex-column-container">
            <div className="stepper-circle-container">
              <section
                className={["mr-10", imageIndex === 1 ? "pulse" : ""].join(" ")}
                onClick={() => changeImage(1)}
              >
                <div
                  className={[
                    "stepper-circle-multi",
                    imageIndex === 1 ? "active-stepper" : "",
                    imageIndex === 1 ? "fade-in-fwd" : "",
                  ].join(" ")}
                >
                  <div className="stepper-circle-multi-inner"></div>
                </div>
              </section>

              <section
                className={["mr-10", imageIndex === 2 ? "pulse" : ""].join(" ")}
                onClick={() => changeImage(2)}
              >
                <div
                  className={[
                    "stepper-circle-multi",
                    imageIndex === 2 ? "active-stepper" : "",
                    imageIndex === 2 ? "fade-in-fwd" : "",
                  ].join(" ")}
                >
                  <div className="stepper-circle-multi-inner"></div>
                </div>
              </section>

              <section
                className={["", imageIndex === 3 ? "pulse" : ""].join(" ")}
                onClick={() => changeImage(3)}
              >
                <div
                  className={[
                    "stepper-circle-multi",
                    imageIndex === 3 ? "active-stepper" : "",
                    imageIndex === 3 ? "fade-in-fwd" : "",
                  ].join(" ")}
                >
                  <div className="stepper-circle-multi-inner"></div>
                </div>
              </section>
            </div>
            <span className="stepper-location-name-active">Chamber</span>
            <span className="locations-btn-link" onClick={handleClickOpen}>
              All Locations
            </span>
          </div>
          <IconButton className="prev-next-icon" onClick={() => goNext()}>
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </div>
    </Fullscreen>
  );
};

export default LocationThree;
