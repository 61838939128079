import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {

  const navigate = useNavigate();

  const navigateToHome = () => navigate("/");

  return (
    <div className="page-container">
      <div className="not-found-container">
        <span className="not-found-heading">404</span>
        <span className="not-found-sub-heading">Page not found</span>
        <span className="not-found-desc">The page you are looking for doesn't exist or another error occured.</span>
        <span className="not-found-desc">Go to <span className="not-found-link-text" onClick={navigateToHome}>Main Page</span></span>
      </div>
    </div>
  );
};

export default NotFound;
