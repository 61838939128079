import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

const Video = () => {
  const navigate = useNavigate();

  const navigateToLanding = () => navigate("/");

  return (
    <div className="video-page-container">
      <div className="video-bg">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=Swc-7lwdYTA"
          width="100vw"
          height="100vh"
          loop="true"
          playing="true"
          muted="true"
          controls="false"
        />
        <Button
          className="start-tour-btn"
          variant="contained"
          onClick={navigateToLanding}
        >
          START TOUR
        </Button>
      </div>
    </div>
  );
};

export default Video;
