import React, { useRef } from "react";
import Button from "@mui/material/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import tutorialSlideOne from "../../images/tutorials/tutorial_1.png";
import tutorialSlideTwo from "../../images/tutorials/tutorial_4.png";
import tutorialSlideThree from "../../images/tutorials/tutorial_3.png";
import tutorialSlideFour from "../../images/tutorials/tutorial_5.png";

const Tutorial = () => {
  const swiperRef = useRef();

  const navigate = useNavigate();

  const navigateToLocationOne = () => navigate("/public-entrance");

  return (
    <div className="page-container">
      <div className="tutorial-bg">
        <div className="tutorial-backdrop">
          <div className="tutorial-dialog-container">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              <SwiperSlide>
                <div className="flex-column-container">
                  <img
                    className="tutorial-slide-img"
                    src={tutorialSlideOne}
                    alt=""
                  />
                  <span className="tutorial-desc">
                    To access detailed information about a particular hotspot,
                    simply click on the yellow bubble associated with it.
                  </span>
                  <div className="ellipse-group">
                    <div className="ellipse-circle-active" onClick={() => swiperRef.current.slideTo(0)}></div>
                    <div className="ellipse-circle" onClick={() => swiperRef.current.slideTo(1)}></div>
                    <div className="ellipse-circle" onClick={() => swiperRef.current.slideTo(2)}></div>
                    <div className="ellipse-circle-final" onClick={() => swiperRef.current.slideTo(3)}></div>
                  </div>
                  <Button
                    className="tutorial-next-btn"
                    variant="contained"
                    onClick={() => swiperRef.current.slideNext()}
                  >
                    Next
                  </Button>
                  <span className="skip-link" onClick={navigateToLocationOne}>
                    Skip
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex-column-container">
                  <img
                    className="tutorial-slide-img"
                    src={tutorialSlideTwo}
                    alt=""
                  />
                  <span className="tutorial-desc">
                    To view all available locations and navigate to any of them,
                    simply click on the 'All Locations' button located in the
                    left-hand corner.
                  </span>
                  <div className="ellipse-group">
                    <div className="ellipse-circle-active" onClick={() => swiperRef.current.slideTo(0)}></div>
                    <div className="ellipse-circle-active" onClick={() => swiperRef.current.slideTo(1)}></div>
                    <div className="ellipse-circle" onClick={() => swiperRef.current.slideTo(2)}></div>
                    <div className="ellipse-circle-final" onClick={() => swiperRef.current.slideTo(3)}></div>
                  </div>
                  <Button
                    className="tutorial-next-btn"
                    variant="contained"
                    onClick={() => swiperRef.current.slideNext()}
                  >
                    Next
                  </Button>
                  <span className="skip-link" onClick={navigateToLocationOne}>
                    Skip
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex-column-container">
                  <img
                    className="tutorial-slide-img"
                    src={tutorialSlideThree}
                    alt=""
                  />
                  <span className="tutorial-desc">
                    To move to the next or previous location, simply use the
                    left and right control buttons located at the bottom of the
                    screen.
                  </span>
                  <div className="ellipse-group">
                    <div className="ellipse-circle-active" onClick={() => swiperRef.current.slideTo(0)}></div>
                    <div className="ellipse-circle-active" onClick={() => swiperRef.current.slideTo(1)}></div>
                    <div className="ellipse-circle-active" onClick={() => swiperRef.current.slideTo(2)}></div>
                    <div className="ellipse-circle-final" onClick={() => swiperRef.current.slideTo(3)}></div>
                  </div>
                  <Button
                    className="tutorial-next-btn"
                    variant="contained"
                    onClick={() => swiperRef.current.slideNext()}
                  >
                    Next
                  </Button>
                  <span className="skip-link" onClick={navigateToLocationOne}>
                    Skip
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex-column-container">
                  <img
                    className="tutorial-slide-img"
                    src={tutorialSlideFour}
                    alt=""
                  />
                  <span className="tutorial-desc">
                    To explore the 360-degree view of the location, swipe the
                    screen left, right, up and down sides.
                  </span>
                  <div className="ellipse-group">
                    <div className="ellipse-circle-active" onClick={() => swiperRef.current.slideTo(0)}></div>
                    <div className="ellipse-circle-active" onClick={() => swiperRef.current.slideTo(1)}></div>
                    <div className="ellipse-circle-active" onClick={() => swiperRef.current.slideTo(2)}></div>
                    <div className="ellipse-circle-active-final" onClick={() => swiperRef.current.slideTo(3)}></div>
                  </div>
                  <Button
                    className="tutorial-next-btn"
                    variant="contained"
                    onClick={navigateToLocationOne}
                  >
                    Next
                  </Button>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
